/**
 * Components for displaying PnL (Profit and Loss) information for cryptocurrencies.
 * Includes functionality to set and submit average prices, and displays warnings for overridden balances
 * or missing coin data.
 */
import {
  useBulkPnL,
  useRemoveAveragePrice,
  useSubmitAveragePrice
} from "../../../query-hooks/dashboard-query-hooks/useDashboard";
import { AssetPnLStatsResultViewModel } from "@hodl.nl/hodltradingdesk";
import { Table } from "react-bootstrap";
import { WarningPopup } from "./WarningPopup";
import styles from "./PnLTableComponent.module.scss";
import { CryptoCurrencyListViewModel } from "@hodl.nl/hodltradingdesk";

// Individual row component for a single currency
function PnLTableRow({ 
  cryptocurrency, 
  pnlData,
  onSubmitPrice,
  onRemovePrice,
  isSubmitting,
  isError,
  isSuccess 
}: { 
  cryptocurrency: CryptoCurrencyListViewModel,
  pnlData: any,
  onSubmitPrice: (currencyId: string, price: number) => void,
  onRemovePrice: (currencyId: string) => void,
  isSubmitting: boolean,
  isError: boolean,
  isSuccess: boolean
}) {
  const data = pnlData?.data;

  const handleSubmitPrice = (price: number) => {
    onSubmitPrice(cryptocurrency.id!, price);
  };

  const handleRemovePriceOverride = () => {
    onRemovePrice(cryptocurrency.id!);
  };

  const renderWarningPopup = () => {
    let result = data?.result;
    const showWarning = Boolean(
      (result?.missingCoinAmount != null && result?.missingCoinAmount > 0) ||
      result?.hasOverriddenStartingBalance
    );

    if (!showWarning || !result?.baseAssetSymbol || !result?.quoteAssetSymbol) return null;

    const totalAdjustedAmount = ((result?.missingCoinAmount || 0) + (result?.manualBalanceEntries?.amount || 0));

    return (
      <WarningPopup
        hasOverriddenStartingBalance={Boolean(result.hasOverriddenStartingBalance)}
        missingCoinAmount={result.missingCoinAmount || 0}
        overriddenPrice={result.manualBalanceEntries?.price || 0}
        baseAssetSymbol={result.baseAssetSymbol}
        totalAdjustedAmount={totalAdjustedAmount}
        quoteAssetSymbol={result.quoteAssetSymbol}
        onSubmitPrice={handleSubmitPrice}
        onRemovePrice={handleRemovePriceOverride}
        isSubmitting={isSubmitting}
        isError={isError}
        isSuccess={isSuccess}
      />
    );
  };

  if (!data?.success) {
    return null;
  }

  var result = data?.result;

  if (!result?.fundStats?.[0]) {
    return (
      <tr>
        <td>{cryptocurrency.symbol}</td>
        <td colSpan={9} className="text-center">No data available</td>
      </tr>
    );
  }

  const fund = result.fundStats[0];
  return (
    <tr>
      <td>
        {result.baseAssetSymbol}
        {renderWarningPopup()}
      </td>
      <td>{fund?.currentAmount?.toFixed(6)}</td>
      <td>{fund?.currentPrice?.toFixed(8)}</td>
      <td>{((fund?.currentPrice ?? 0) * (fund?.currentAmount ?? 0)).toFixed(8)}</td>
      <td>{fund?.weightedAverageCostBasis?.toFixed(8)}</td>
      <td>{fund?.costs?.toFixed(8)}</td>
      <td>{fund?.proceeds?.toFixed(8)}</td>
      <td>
        {fund?.realizedPnL?.toFixed(6)} {result?.quoteAssetSymbol}
      </td>
      <td>
        {fund?.unrealizedPnL?.toFixed(6)} {result?.quoteAssetSymbol}
      </td>
      <td>
        {fund?.totalPnL?.toFixed(6)} {result?.quoteAssetSymbol} ({fund?.totalPnLPercentage?.toFixed(2)}%)
      </td>
    </tr>
  );
}

// Table component that renders multiple currency rows
export function PnLTableComponent({ currencies }: { currencies: CryptoCurrencyListViewModel[] }) {
  const submitAveragePrice = useSubmitAveragePrice();
  const removeAveragePrice = useRemoveAveragePrice();
  const bulkPnL = useBulkPnL(currencies.map(c => c.id!));

  const handleSubmitPrice = (currencyId: string, price: number) => {
    submitAveragePrice.mutate({
      currencyId,
      avgPrice: price
    });
  };

  const handleRemovePrice = (currencyId: string) => {
    removeAveragePrice.mutate({
      currencyId,
    });
  };

  if (bulkPnL.isLoading) {
    return <div className="text-center">Loading PnL data...</div>;
  }

  if (bulkPnL.isError) {
    return <div className="text-center">Error loading PnL data</div>;
  }

  const pnlDataMap = new Map(
    bulkPnL.data?.data?.map((item: AssetPnLStatsResultViewModel) => [
      item.result?.baseAssetId,
      { data: { success: true, result: item.result } }
    ])
  );

  return (
    <div>
      <div className={styles.tableContainer} >
        <Table responsive>
          <thead>
            <tr>
              <th>Currency</th>
              <th>Current Amount</th>
              <th>Current Price</th>
              <th>BTC Value</th>
              <th>Avg Price</th>
              <th>Total Cost</th>
              <th>Total Proceeds</th>
              <th>Realized PnL</th>
              <th>Unrealized PnL</th>
              <th>Total PnL</th>
            </tr>
          </thead>
          <tbody>
            {currencies
              .sort((a, b) => (a.symbol || "").localeCompare(b.symbol || ""))
              .map(cryptocurrency => (
                <PnLTableRow 
                  key={cryptocurrency.id} 
                  cryptocurrency={cryptocurrency}
                  pnlData={pnlDataMap.get(cryptocurrency.id)}
                  onSubmitPrice={handleSubmitPrice}
                  onRemovePrice={handleRemovePrice}
                  isSubmitting={submitAveragePrice.isLoading}
                  isError={submitAveragePrice.isError}
                  isSuccess={submitAveragePrice.isSuccess}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Currency</th>
              <th>Current Amount</th>
              <th>Current Price</th>
              <th>BTC Value</th>
              <th>Avg Price</th>
              <th>Total Cost</th>
              <th>Total Proceeds</th>
              <th>Realized PnL</th>
              <th>Unrealized PnL</th>
              <th>Total PnL</th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
}
