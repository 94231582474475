/**
 * Component for displaying average entry and exit prices for cryptocurrencies.
 * Allows users to select a currency and view either entry or exit price averages.
 */
import React, { useState } from "react";
import styles from "./Averages.module.scss"
import { useSandboxCurrencies } from "../../../query-hooks/dashboard-query-hooks/useDashboard";
import DashboardComponent from "../../../Components/DashboardComponent";
import { Row, Col } from "react-bootstrap";
import { AveragesTableComponent } from "./AveragesTableComponent";
import { BiSelectMultiple } from "react-icons/bi";

const Averages = () => {
    const [selectedCrypto, setSelectedCrypto] = useState();
    const [orderDirection, setOrderDirection] = useState();
    const currencies = useSandboxCurrencies();

    return (
        <DashboardComponent 
            title="Averages Dashboard" 
            subtitle="Entry and Exit Price Statistics"
            toCreate={false}
            buttonMessage=""
        >
            <Row>
                <Col xl={4} className="my-2">
                    <select 
                        className={styles.selectInput} 
                        onChange={e => setSelectedCrypto(e.target.value)} 
                        defaultValue="1"
                    >
                        <option value="1" disabled>Select Currency</option>
                        {currencies.isSuccess && 
                            currencies.data
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(currency => (
                                    <option key={currency.id} value={currency.id}>
                                        {currency.name}
                                    </option>
                                ))
                        }
                    </select>
                </Col>
                <Col xl={4} className="my-2">
                    <select 
                        className={styles.selectInput} 
                        onChange={e => setOrderDirection(e.target.value)} 
                        defaultValue="1"
                    >
                        <option value="1" disabled>Select Type</option>
                        <option value="Buy">Average Entry Prices</option>
                        <option value="Sell">Average Exit Prices</option>
                    </select>
                </Col>
            </Row>
            <Row>
                {(!selectedCrypto || !orderDirection) && (
                    <div className={styles.selectMessage}>
                        <BiSelectMultiple />
                        <h5>Select Currency and Price Type</h5>
                    </div>
                )}
            </Row>

            {selectedCrypto && orderDirection && (
                <AveragesTableComponent 
                    selectedCrypto={selectedCrypto} 
                    orderDirection={orderDirection} 
                />
            )}
        </DashboardComponent>
    );
};

export default Averages;
