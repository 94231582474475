import { useState } from "react"
import { Col } from "react-bootstrap"
import styles from "./SelectInput.module.scss"
import { useAuth } from '../../../Hooks/useAuth'
import { useAllBenchmarkQuotes } from "../../../query-hooks/benchmark-query-hooks/useBenchmark"

const SelectInputQuotes = ({ selectedQuotes, setSelectedQuotes }) => {
    const { darkmode } = useAuth()
    const allQuotes = useAllBenchmarkQuotes();
    const [selectedValue, setSelectedValue] = useState("")

    const addQuote = quote => {
        // If the quote is already in the selectedQuotes array, return
        if (selectedQuotes.includes(quote)) return
        // Otherwise, add the quote to the selectedQuotes array
        setSelectedQuotes([ ...selectedQuotes, quote ])
    }

    const removeQuote = quote => {
        setSelectedQuotes(selectedQuotes.filter(selectedQuote => selectedQuote !== quote))
        setSelectedValue("")
    }
    
    if (allQuotes.isSuccess) {
        return (
            <Col md={12} className="py-1">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select Benchmark</h6>
                <div style={{position:"relative"}}>
                    <select 
                        className={styles.selectInput} 
                        onChange={e => addQuote(e.target.value)}
                        value={selectedValue}
                    > 
                        <option value="">Select Benchmark</option>
                        { allQuotes.data.map(quote => <option key={quote.id} value={quote.symbol}>{quote.symbol}</option> )}
                    </select>
                </div>
                <div>
                    { selectedQuotes.map((quote, i) => 
                        <div key={i} className={styles.selectedQuotes} onClick={() => removeQuote(quote)}>
                            {quote}
                            <span className={styles.removeIcon} onClick={() => setSelectedQuotes(selectedQuotes.filter(selected => selected !== quote))}>
                                x
                            </span>
                        </div>
                    )}
                </div>
            </Col>
        ) 
    }
    if (allQuotes.isLoading) return <p>Loading...</p>
} 

export default SelectInputQuotes;