/**
 * Dashboard component dedicated to displaying Profit and Loss information.
 * Provides a comprehensive view of PnL across different cryptocurrencies.
 */
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import DashboardComponent from "../../../Components/DashboardComponent";
import { PnLTableComponent } from "./PnLTableComponent";
import { BiSelectMultiple } from "react-icons/bi";
import styles from "./PnLDashboard.module.scss";
import useFunds, {
  useFundHoldings,
} from "../../../query-hooks/fund-query-hooks/useFund";
import {
  CryptoCurrencyListViewModel,
  FundListViewModel,
  FundListViewModelPagingViewModel,
  HoldingListViewModel,
} from "@hodl.nl/hodltradingdesk";
import { UseQueryResult } from "react-query";
import { useLocalStorage } from "../../../Hooks/useLocalStorage";
import useCurrencies from "../../../query-hooks/currency-query-hooks/useCurrency";
import useCryptos from "../../../query-hooks/crypto-query-hooks/useCryptos";

const PnLDashboard = () => {
  const funds: UseQueryResult<FundListViewModelPagingViewModel, any> =
    useFunds(0);
  const [showAll, setShowAll] = useState(false);
  const [selectedFund, setSelectedFund] = useLocalStorage("PnL_fund", "");
  const holdings = useFundHoldings(selectedFund, true);
  const allUsedCurrencies= useCryptos();
  const [currencies, setCurrencies] = useState<CryptoCurrencyListViewModel[]>([]);

  useEffect(() => {
    if (holdings.isSuccess && allUsedCurrencies.isSuccess) {
      var items = showAll
        ? allUsedCurrencies.data
        : holdings.data
        ?.filter((x: HoldingListViewModel) => !!x.cryptoCurrency)
        .map((x:HoldingListViewModel) => x.cryptoCurrency);
      setCurrencies(items);
    }
  }, [holdings.isSuccess, allUsedCurrencies.isSuccess, showAll]);

  useEffect(() => {
    if (funds.isSuccess && selectedFund == "") {
      setSelectedFund(funds.data?.items![0].id);
    }
  }, [funds.isSuccess]);

  // TODO: also allow filter on past holdings / show all
  return (
    <DashboardComponent
      title="Profit and Loss Dashboard"
      subtitle="PnL Statistics"
      toCreate={false}
      buttonMessage=""
    >
      <Row>
        <Col xl={5} className="my-2">
          <div className="d-flex align-items-center gap-3">
          <div style={{ width: "15px" }}></div>
            <select
              className={`${styles.selectInput} ml-2`}
              onChange={(e) => setSelectedFund(e.target.value)}
              value={selectedFund}
              disabled={showAll}
            >
              <option value="1" disabled>
                Select Fund
              </option>
              {funds.isSuccess &&
                funds.data
                  .items! // only main funds
                  .sort((a: FundListViewModel, b: FundListViewModel) =>
                    a.fundName!.localeCompare(b.fundName!)
                  )
                  .map((fund: FundListViewModel) => (
                    <option key={fund.id} value={fund.id}>
                      {fund.fundName}
                    </option>
                  ))}
            </select>
            {
          /* show all currencies seems to have a bug. reenable when asked for
            <div className="form-check" style={{ width: "80%" }}>
              <input
                className="form-check-input"
                type="checkbox"
                id="showAllCheckbox"
                checked={showAll}
                onChange={(e) => setShowAll(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="showAllCheckbox">
                Show All
              </label>
            </div>
            */
            }
          </div>
        </Col>
      </Row>
      <Row>
        {!selectedFund && (
          <div className={styles.selectMessage}>
            <BiSelectMultiple />
            <h5>Select Fund to View PnL</h5>
          </div>
        )}
      </Row>

      {
      currencies.length > 0 && <Row>
        <Col xl={12}>
          <PnLTableComponent currencies={currencies} />
          </Col>
        </Row>
      }
    </DashboardComponent>
  );
};
export default PnLDashboard;
