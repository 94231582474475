import axios from "axios";
import {useQuery, useQueryClient, useMutation} from "react-query";
import {API_URL} from "../../settings";
import {simpleSuccessfulMessage} from "../../Helpers/Helpers"
import {StatisticsApi} from "@hodl.nl/hodltradingdesk"
import {useAuth} from "../../Hooks/useAuth";

// Function to get main dashboard data 
const fetchDashboard = async () => await axios.get(`${API_URL}/dashboard`).then(response => response.data);
export default function useDashboard() {
  return useQuery("dashboard", fetchDashboard);
}

// Function to get fund detail for dashboard data 
const fetchFundDetail = async (fundId: string) => await axios.get(`${API_URL}/dashboard`).then(response => response.data.find((item: any) => item.id === fundId));

export function useFundDetail(fundId: string) {
  return useQuery(["fundDetail", fundId], () => fetchFundDetail(fundId));
}

// Function to get Fund Holdings for dashboard data 
const fetchFundHoldings = async (fundId: string) => await axios.get(`${API_URL}/dashboard/${fundId}/holdings`).then(response => response.data);

export function useFundHoldings(fundId: string) {
  return useQuery(["fundHoldings", fundId], () => fetchFundHoldings(fundId), {enabled: fundId != null});
}

// Function to get Fund Trades Summary for dashboard data 
const fetchFundTrades = async (fundId: string) => await axios.get(`${API_URL}/dashboard/${fundId}/summary/trades`).then(response => response.data);

export function useFundTrades(fundId: string) {
  return useQuery(["fundTrades", fundId], () => fetchFundTrades(fundId));
}

// Function to get Fund Staking Summary for dashboard data 
const fetchFundStaking = async (fundId: string) => await axios.get(`${API_URL}/dashboard/${fundId}/summary/staking`).then(response => response.data);

export function useFundStaking(fundId: string) {
  return useQuery(["fundStaking", fundId], () => fetchFundStaking(fundId));
}

// Function to get Categories by Group for Main Charts Dashboard
const fetchCategoryGroupsDashboard = (fundId: string, categoryGroup: string, recursive: boolean) => axios.get(`${API_URL}/dashboard/${fundId}/categories/${categoryGroup}`, {params: {recursive}}).then(res => res.data);

export function useFundCategories(fundId: string, categoryGroup: string, recursive: boolean) {
  return useQuery(["fundCategories", fundId, categoryGroup, recursive], () => fetchCategoryGroupsDashboard(fundId, categoryGroup, recursive), {enabled: categoryGroup !== undefined})
}

// SANDBOX
// Function to get Fund Data by its ID to handle in Sandbox
const fetchFundSandboxData = (fundId: string, queryString: string) => axios.get(`${API_URL}/sandbox/${fundId}`).then(res => res.data.filter((data: any) => data.cryptoCurrency.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
export const useSandboxFundData = (fundId: string, queryString: string) => useQuery(["sandboxFund", fundId, queryString], () => fetchFundSandboxData(fundId, queryString))

// Function to get all Sandbox Currencies
const fetchSandboxCurrencies = () => axios.get(`${API_URL}/sandbox/currencies`).then(res => res.data)
export const useSandboxCurrencies = () => useQuery("sandboxCurrencies", fetchSandboxCurrencies, {retry: false})

//MUTATIONS
// Function to add currency to the Sandbox
const addSandboxCurrency = (currencyId: string) => axios.put(`${API_URL}/sandbox/currencies`, currencyId).then(res => res.data)
export const useAddSandboxCurrency = () => {
  const queryClient = useQueryClient()
  return useMutation((currencyId: string) => addSandboxCurrency(currencyId), {
    onSuccess: async () => {
      queryClient.invalidateQueries("sandboxCurrencies")
      simpleSuccessfulMessage("Sandbox Currencies have been stored succesfully")
    }
  })
}

// STATISTICS AVERAGES
// Function to get average prices by Base Asset and Order Direction
const fetchAveragePrices = (baseAssetId: string, orderDirection: string) => axios.get(`${API_URL}/statistics/averageprices/${baseAssetId}/${orderDirection}`).then(res => res.data)
export const useAveragePrices = (baseAssetId: string, orderDirection: string) => useQuery(["averagePrices", baseAssetId, orderDirection], () => fetchAveragePrices(baseAssetId, orderDirection), {enabled: baseAssetId !== undefined && orderDirection !== undefined})

export const usePnL = (baseAssetId: string) => {
  const {axiosInstance} = useAuth();
  return useQuery(["pnl", baseAssetId], () => {
    return new StatisticsApi(undefined, API_URL, axiosInstance).statisticsPnLBaseAssetIdGet(baseAssetId).catch((e)=>{});
  }, {enabled: baseAssetId !== undefined})
}

export const useBulkPnL = (assetIds: string[]) => {
  const {axiosInstance} = useAuth();
  return useQuery(["pnl", "bulk", assetIds], () => {
    return new StatisticsApi(undefined, API_URL, axiosInstance).statisticsPnLGet(assetIds).catch((e)=>{});
  }, {enabled: assetIds !== undefined && assetIds.length > 0})
}

export function useSubmitAveragePrice() {
  const {axiosInstance} = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({currencyId, avgPrice}: {currencyId: string, avgPrice: number}) => {
      return new StatisticsApi(undefined, API_URL, axiosInstance).statisticsPnLSetBasePricePost({cryptoId: currencyId, price: avgPrice});
    },
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(["pnl", params.currencyId]);
        queryClient.invalidateQueries(["pnl", "bulk"]);
      }
    }
  );
}

export function useRemoveAveragePrice() {
  const {axiosInstance} = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    ({currencyId}: {currencyId: string}) => {
      return new StatisticsApi(undefined, API_URL, axiosInstance).statisticsPnLRemoveBasePriceDelete(currencyId);
    },
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(["pnl", params.currencyId]);
        queryClient.invalidateQueries(["pnl", "bulk"]);
      }
    }
  );
}
