import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import "./BenchmarkChart.css"
import { Row, Col } from "react-bootstrap"
import styles from "../BenchmarkDashboard.module.scss"
import { IoIosDownload } from "react-icons/io";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: { padding: 10 },
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label 
                    if (label) {
                        label = `${label}: `;
                    }
                    if (context.parsed.y !== null) {
                        label = `${label} ${context.parsed.y}%`;
                    }
                    return label;
                }
            }
        },
        legend: {
            position: "bottom",
            align: "center",
            labels: {
                font: { size: 20, family: "LarkenLight" },
                padding: 20
            },
        },
        datalabels: { display: false },
    },
    scales: {
        y: {
            ticks: {
                callback: function(val, index) {
                    return `${val}%`;
                },
                autoSkip: false, 
                font: { family: "LarkenMedium", size: 22 }, 
                padding: 10
            },
            grid: { display: true },
            border: { color: "black", width: 3 }, // Axis color and line weight
            offset: true, // Separation between chart line and axis line
        },
        x: {
            ticks: { 
                callback: function(val, index, values) {
                    const valuesLength = values.length;
                    const dateString = this.getLabelForValue(index) // Date in a string format YYYY-MM-DD
                    const [, month] = dateString.split('-').map(Number); // Get month number

                    // If range is larger than 12 months, Do not display before last date or second date to avoid date overlapping with first or last
                    if (valuesLength >= 40 && dateString.includes('6-2024')) {
                        return ''
                    }

                    // Display ticks for the first, last, and predefined months (June and December)
                    if (index === 0 || index === valuesLength - 1 || [6, 12].some(x => x === month)) {
                        return dateString.replace(/-/g, '/');
                    }
                },
                autoSkip: false,
                font: { family: "LarkenMedium", size: 22 }, 
                padding: 10,
                maxRotation: 0,
                minRotation: 0,
            },
            grid: { display: false },
            border: { color: "black", width: 3 }, // Axis color and line weight
            offset: true, // Separation between chart line and axis line
        }
    }
};

// Function to format date for x label style
const dateFormatter = dateString => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '-' + month + '-' + year
}


export function FundAssetsChart({ hookedData, returnsChartData, fund, linechartRef, handleDownloadImage, handleDownloadSVG, transformedFundName }) {
    const [ graphLabels, setGraphLabels ] = useState([])
    const [ graphData, setGraphData ] = useState([])

    useEffect(() => {
        // 1 GET FUND DATA
        const fundData = hookedData[fund.id]
        // Function to retrieve only dates from fund data array
        const getMonthlyReturnsDates = arrayData => arrayData.map((element, i, array) => array[i] && ({date: element.date})).filter(val => typeof val !== 'undefined')
        const fundMonthlyReturnsDates = getMonthlyReturnsDates(fundData)
        // Set dates for graph labels
        setGraphLabels(fundMonthlyReturnsDates.map(x => x.date))

        // Quotes Calculations 
        const quotesMonthlyReturns = []
        const colorsArray =  ['#37513c', '#97dca5', '#9fe7ae', '#a7f3b7', '#affec0', '#b7f9c8', '#dffff1', '#e7fff9', '#efffff', '#f7ffff', '#ffffff']
        returnsChartData.chartQuotes.forEach((element, i) => {
            if (returnsChartData.returns[element.id]) {
                const quote = returnsChartData.returns[element.id]
                const monthlyReturns = { 
                    label: element.name,
                    data: quote.map(item => ((item.normalizedValue - 1) * 100).toFixed(2)),
                    borderColor: colorsArray[i],
                    backgroundColor: colorsArray[i],
                }
                quotesMonthlyReturns.push(monthlyReturns)
            }
        });

        const colorFilter = fund => {
            if (fund.toLowerCase().includes("genesis")) return "#669771"
            if (fund.toLowerCase().includes("algorithmic")) return "#6D9E93"
            if (fund.toLowerCase().includes("oracle")) return "#6D9E93"
        }

        setGraphData([
            {
                label: transformedFundName,
                data: fundData.map(item => ((item.normalizedValue - 1) * 100).toFixed(2)),
                borderColor: '#669771',
                backgroundColor: '#669771',
            },
            ...quotesMonthlyReturns
        ])
    }, [ hookedData ])

    const data = {
        labels: graphLabels && [...graphLabels.map(label => dateFormatter(label) )], // Added First Date value as empty to start from Zero
        datasets: graphData
    };

    return (
        <>
            <div className={styles.dropdown} title="Download Chart Image">
                <IoIosDownload className={styles.downloadIcon} />
                <div className={styles.dropdownContent}>
                    <p onClick={() => handleDownloadImage("linechart", linechartRef)}>Download as PNG</p>
                    <p onClick={() => handleDownloadSVG("linechart", linechartRef)}>Download as SVG</p>
                </div>
            </div>
            <span className={styles.chartTitle} style={{fontFamily:"LarkenMedium"}}>{ transformedFundName } Performance and Other Assets Chart</span>
            <Row>
                <Col xl={12} className={styles.chartsContainer} ref={linechartRef}>
                    <Line options={options} data={data} height={600} />
                </Col>
            </Row>
        </>
    ) 
}
