import { BenchmarkCandle } from "../model/benchmarkCandle";

export function yoyReturn(weightedSums: BenchmarkCandle[]) {
    var last = weightedSums[weightedSums.length - 1];
    var [year, month] = last.date.split("-");
    var lastYear = parseInt(year) - 1;
    
    var lastYearIndex = weightedSums.findIndex((x) => 
        x.date.startsWith(`${lastYear}-${month}`)
    );
    
    if (lastYearIndex === -1) {
        return 0;
    }
    
    var lastYearValue = weightedSums[lastYearIndex];
    return (last.normalizedValue - lastYearValue.normalizedValue) / lastYearValue.normalizedValue;
}
